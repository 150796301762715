import logo from "../logo.svg";
import "../App.css";
import { useEffect } from "react";

function Other() {
    return (
        <div className="App">
            <div>This is some placeholder text above</div>
         
<div data-entity="EMBED_FORM" data-source="CharityStack" id="1ee672b7-2d0e-4e4c-bb27-8abd886a5946"></div>
            <div>This is some placeholder text below</div>
        </div>
    );
}

export default Other;
