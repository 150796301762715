import logo from "./logo.svg";
import "./App.css";
import { useEffect } from "react";
import Home from "./Pages/Home";
import Other from "./Pages/Other";
import DTD from "./Pages/DTD";
import { BrowserRouter, Routes, Route } from "react-router-dom";

function App() {
    useEffect(() => {
        // insert script tag
        const script = document.createElement("script");
        script.src = process.env.REACT_APP_CS_JS_SCRIPT;
        // append script to head
        document.body.appendChild(script);
    }, []);
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/quantity" element={<Home />} />
                <Route path="/other" element={<Other />} />
                <Route path="/dtd" element={<DTD />} />
            </Routes>
        </BrowserRouter>
    );
}

export default App;
