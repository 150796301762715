import logo from "../logo.svg";
import "../App.css";
import { useEffect } from "react";
import "../styles.css";

function Home() {
    useEffect(() => {
        // create an event listener that listens for a message from the iframe
        window.addEventListener("message", (event) => {
            if (event.data.action !== "IFRAME_LOADED") {
                return;
            }
            const data = JSON.parse(event.data.data);
            const iframeID = data.elementID;
            const iframe = document.getElementById(iframeID);

            console.log("iframeID", iframeID);
            console.log("iframe", iframe);

            // store css content from styles.css into a variable
            const cssContent = `                 
                #57f555bd-771f-4992-a749-65a98e5f328d-CS-fundraising-bar-pill-text-right {
                    opacity: 0 !important;
                }
                    #dcb87a10-4825-48a6-b005-970db53cf45f-CS-fundraising-bar-pill-text-right {
                    opacity: 0 !important;
                }
            `;

            // #dcb87a10-4825-48a6-b005-970db53cf45f-CS-fundraising-bar-pill {
            //         background-color: red !important;
            //     }

            //     #dcb87a10-4825-48a6-b005-970db53cf45f-CS-fundraising-bar-pill-text {
            //         font-size: 300px !important;
            //     }

            // send CSS content to iframe
            iframe.contentWindow.postMessage(
                {
                    action: "ADD_CSS_CONTENT",
                    entity: "CHARITYSTACK",
                    sender: "PARENT_WEBSITE",
                    receiver: "FUNDRAISING_BAR",
                    data: cssContent,
                    id: iframeID,
                },
                "*"
            );
        });
    }, []);

    return (
        <div className="App">
            <div>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce
                sollicitudin enim et dolor pellentesque, et aliquam velit
                vestibulum. Morbi condimentum felis eget tortor congue, id
                convallis turpis fringilla. Duis rutrum tellus et sapien
            </div>

            <div
                data-entity="FUNDRAISING_BAR"
                data-source="CharityStack"
                id="dcb87a10-4825-48a6-b005-970db53cf45f"></div>

            <div
                data-entity="EMBED_FORM"
                data-source="CharityStack"
                id="5b8fb86a-64d6-4ce9-9ade-1dbcd141641f"></div>
            <div
                data-entity="EMBED_FORM"
                data-source="CharityStack"
                id="7411b948-a91c-4ff3-abd0-a75273620e48"></div>
            <div
                data-entity="EMBED_FORM"
                data-source="CharityStack"
                id="250a6502-aaf6-48d0-86d4-cbfccae57623"></div>

            <div>
                Cras ullamcorper, justo sit amet sagittis commodo, metus dui
                consequat urna, eget sollicitudin metus erat ut dui. Proin
                aliquam lacus sit amet dolor vulputate tincidunt. Proin gravida
                mauris ex, nec bibendum urna pulvinar vitae. Nullam non pharetra
                purus. Proin bibendum
            </div>
        </div>
    );
}

export default Home;
