import logo from "../logo.svg";
import "../App.css";
import { useEffect } from "react";

function Other() {
    return (
        <div className="App">
            <div>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce
                sollicitudin enim et dolor pellentesque, et aliquam velit
                vestibulum. Morbi condimentum felis eget tortor congue, id
                convallis turpis fringilla. Duis rutrum tellus et sapien
                convallis, id varius dui fringilla. Curabitur in urna id nisi
                congue consectetur. Nunc mattis nunc sed mi pharetra, id lacinia
                felis egestas. Mauris eget turpis feugiat, consequat ex id,
                venenatis sapien. Aliquam feugiat justo nec odio malesuada
                pulvinar. Vestibulum vulputate malesuada nulla, et interdum
                tortor tempor non. Nullam cursus neque ut placerat lacinia.
                Mauris tristique sagittis mauris, nec fermentum nisl feugiat in.
                Sed eu fringilla lectus. Vivamus sit amet tincidunt enim. Mauris
                hendrerit purus auctor, consectetur purus in, aliquam justo.
                Aenean ullamcorper sem vel tristique fringilla. Sed quis lacinia
                elit, nec efficitur urna. Nulla facilisi. Donec eu ultricies
                dui, nec egestas tortor. Ut vestibulum, neque id consequat
                laoreet, justo lorem egestas nulla, at elementum mauris libero
                vitae erat. Integer varius, odio non dapibus congue, nunc mi
                consectetur mauris, a accumsan erat odio vel urna. Donec
                fringilla diam ac nisi tempus eleifend. Sed auctor tellus ac
                quam congue, sit amet varius justo volutpat. Cras efficitur
                risus augue, nec ultrices est faucibus ut. Curabitur suscipit
                lobortis nibh sed venenatis. Fusce in ipsum varius, venenatis
                eros id, placerat velit. Sed varius cursus tortor, non
                pellentesque dui tincidunt sed. Nam ac diam bibendum, placerat
                turpis eget, fringilla risus. Integer eget lacus eu erat
                pulvinar viverra. Proin accumsan, sapien et finibus tempus,
                justo arcu ultrices mi, eget feugiat nisi quam nec lectus.
                Quisque pellentesque nunc et elit lobortis finibus. Vivamus quis
                odio pharetra, maximus elit ut, tristique orci. Integer
                porttitor, ligula in tincidunt finibus, velit elit vulputate
                mauris, sit amet sollicitudin urna sem in turpis. Phasellus
                venenatis mauris sit amet lectus interdum viverra. Quisque
                cursus magna non ex eleifend, a pulvinar turpis mattis. Praesent
                quis gravida neque, a efficitur ligula. Nullam pharetra ligula
                ac massa sollicitudin, sed tempus risus tincidunt. Ut vestibulum
                quam id leo tempus, non lobortis justo ullamcorper. Cras
                ullamcorper, justo sit amet sagittis commodo, metus dui
                consequat urna, eget sollicitudin metus erat ut dui. Proin
                aliquam lacus sit amet dolor vulputate tincidunt. Proin gravida
                mauris ex, nec bibendum urna pulvinar vitae. Nullam non pharetra
                purus. Proin bibendum
            </div>
            <div
                data-entity="EMBED_FORM"
                data-source="CharityStack"
                id="2415da9f-f2b5-430f-bf88-58d0555c3476"></div>
            <div
                data-entity="EMBED_FORM"
                data-source="CharityStack"
                id="2415da9f-f2b5-430f-bf88-58d0555c3476"></div>
            <div
                data-entity="EMBED_FORM"
                data-source="CharityStack"
                id="1e1e1063-0fc0-4042-a4f9-65391e6b600f"></div>

            <div
                data-entity="EMBED_FORM"
                data-source="CharityStack"
                id="be53220f-3224-41ab-b9f0-a66456f2d08d"></div>
            <div
                data-entity="EMBED_FORM"
                data-source="CharityStack"
                id="674aff1c-f0a4-4cff-896c-fc09a6511ebb"></div>
            <div>
                Cras ullamcorper, justo sit amet sagittis commodo, metus dui
                consequat urna, eget sollicitudin metus erat ut dui. Proin
                aliquam lacus sit amet dolor vulputate tincidunt. Proin gravida
                mauris ex, nec bibendum urna pulvinar vitae. Nullam non pharetra
                purus. Proin bibendum
            </div>
        </div>
    );
}

export default Other;
